import React, { useEffect, useMemo, useState } from "react"
import { Button } from "antd"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { useAuth0 } from "@auth0/auth0-react"
import { isSubscriptionValid, translatePath, uiHandleError } from "../utils"
import { useGlobalValue } from "../hooks/useGlobalValue"
import { navigate, Link } from "gatsby"
import moment from 'moment'

const GET_USER_WEBINAR_REGISTRATION_QUERY = gql`
  query webinarRegistration($webinarId: uuid!) {
    webinar_registrations(where: { webinar_id: { _eq: $webinarId } }) {
      created_at
      live_room_url
    }
  }
`

const WebinarRegistrationButton = ({ onSuccess, webinar, scheduleId }) => {
  console.log({webinar})

  const { t } = useTranslation()
  const [disabled, setDisabled] = useState(false)
  const { getAccessTokenSilently } = useAuth0()
  const [{ user, isLoadingUser, userSubscription }] = useGlobalValue()
  const premiumUser = isSubscriptionValid(userSubscription)

  const { loading, error, data, refetch } = useQuery(
    GET_USER_WEBINAR_REGISTRATION_QUERY,
    {
      variables: { webinarId: webinar.id },
    }
  )

  const [signingUp, setSigningUp] = useState(false)

  const signupWebinar = async () => {
    setSigningUp(true)
    try {
      // check profile
      if (
        !user?.basic_profile ||
        !user?.basic_profile?.firstname ||
        !user?.basic_profile?.lastname ||
        !user?.basic_profile?.email ||
        !user?.basic_profile?.phone_number
      ) {
        return uiHandleError({
          error: {},
          action: () => {
            navigate("/me/profile")
          },
          message: t("warning:completeYourProfileToContinue"),
        })
      }

      // signup
      const token = await getAccessTokenSilently()
      const url = "/.netlify/functions/signup-webinar"
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ webinarId: webinar.id, scheduleId }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      if (response.status !== 200) {
        return uiHandleError({ error: null, message: response.statusText })
      }

      onSuccess && onSuccess()
      refetch()
    } catch (e) {
      uiHandleError({ error: e })
    }
    setSigningUp(false)
  }

  const login = async () => {
    navigate(`${translatePath("/auth/sign-in")}?redirect=${location.href}`)
  }
  const signUp = async () => {
    navigate(`${translatePath("/auth/sign-up")}?redirect=${location.href}`)
  }

  const [onClick, buttonMessage] = useMemo(() => {
    if (loading || isLoadingUser) {
      return [() => {}, t("button:loading")]
    }

    if (!user) {
      return [login, t("button:subscribeForFreeToWebinar")]
    }

    if (data?.webinar_registrations.length > 0) {
      const liveUrl =
        data?.webinar_registrations[0].live_room_url || webinar.url
      return [
        () => {
          window.open(liveUrl, "_blank")
        },
        liveUrl ? t("button:goToWebinar") : t("button:alreadySignedUp"),
      ]
    }

    if (!premiumUser && webinar.premium_only) {
      return [signUp, t("button:premiumOnlyMembership")]
    }

    return [signupWebinar, t("button:signupForFree")]
  }, [user, loading, isLoadingUser, error, data, userSubscription])

  const [expired, setExpired] = useState(false)

  useEffect(() => {
    const now = moment().add(-1, "hour")

    setExpired(moment(webinar.available_date) < now)
  }, [])

  if (expired) {
    return (
      <div>
        <p>{t("message:webinarExpired")}</p>
        {webinar.url ? (
          <Button
            type="primary"
            className={"yellowsp"}
            block={true}
          >
            <Link to={webinar.url}>
              {t("button:openExpiredWebinar")}
            </Link>
          </Button>
        ) : (
          <Button
            type="primary"
            className={"yellowsp"}
            block={true}
            disabled={true}
          >
            {t("button:webinarExpired")}
          </Button>
        )}
      </div>
    )
  }

  return (
    <>
      <Button
        type="primary"
        className={
          !premiumUser && webinar.premium_only
            ? "premium-only secondary"
            : "yellowsp"
        }
        loading={isLoadingUser || loading || signingUp}
        block={true}
        disabled={disabled}
        onClick={onClick}
      >
        {buttonMessage}
      </Button>
      {!premiumUser && webinar.premium_only && (
        <img
          src="/assets/images/subscription-premium-white.svg"
          alt="subscription premium"
          className="premium-only-tag"
        />
      )}
    </>
  )
}

export default WebinarRegistrationButton
