import React, { useEffect, useState } from "react"
import "./WebinarRegistrationCard.less"
import { formatDate } from "../utils"
import { Button, Col, Modal, Row } from "antd"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import WebinarRegistrationButton from "./WebinarRegistrationButton"
import { CheckCircleFilled } from "@ant-design/icons"
import { useTranslation } from "react-i18next"

const WebinarRegistrationCard = ({ data, showModal }) => {
  const { name, schedules, presenters, landscape, available_date } = data
  const imageData = getImage(landscape?.localFile)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const { t } = useTranslation()

  return (
    <div className="webinar-registration-card-wrapper">
      <div className="header">
        <h1 className="title">{name}</h1>
        {
          <p className="subtitle">
            {formatDate(available_date, { format: "LLLL" })}
          </p>
        }
      </div>
      <Row justify="center" gutter={[16, 16]} align="middle">
        <Col xxl={14} sm={24} className={"webinar-image-wrapper"}>
          <div className="webinar-image">
            <GatsbyImage
              style={{ width: "100%" }}
              image={imageData}
              alt={name || "cover"}
            />
          </div>
        </Col>
        <Col xxl={10} sm={24}>
          <ul className="presenter-list">
            {presenters.map((presenter, index) => (
              <li key={index} className="presenter">
                <Row gutter={[16, 16]} align="middle">
                  <Col xs={6} md={4}>
                    <GatsbyImage
                      style={{ width: "100%" }}
                      image={getImage(presenter.picture?.localFile)}
                      alt={presenter.name || "presentatore"}
                    />
                  </Col>
                  <Col xs={18} md={20}>
                    <h2>
                      {presenter.firstname} {presenter.lastname}
                    </h2>
                    <p>{presenter.title}</p>
                  </Col>
                </Row>
              </li>
            ))}
          </ul>
          <WebinarRegistrationButton
            webinar={data}
            scheduleId={schedules[0]?.schedule}
            onSuccess={() => {
              setShowSuccessModal(true)
            }}
          />
        </Col>
      </Row>

      <Modal
        centered
        className="fullscreen-modal"
        width={"100%"}
        visible={showSuccessModal || showModal}
        title={null}
        footer={null}
        closable={true}
        onCancel={() => {
          setShowSuccessModal(false)
        }}
        onOk={() => {
          setShowSuccessModal(false)
        }}
      >
        <div className="registration-feedback-modal">
          <Row justify="center">
            <Col xs={24} md={12}>
              <div>
                <CheckCircleFilled
                  style={{ fontSize: 130, color: "#F2EA00" }}
                />
                <h1 className="title">{t("message:registrationConfirmed")}</h1>
                <p className="subtitle">{name}</p>
                {schedules[0] && (
                  <p className="subtitle">
                    {formatDate(schedules[0].date, { format: "LLLL" })}
                  </p>
                )}
                <Button
                  type="primary"
                  className="yellowsp"
                  onClick={() => {
                    setShowSuccessModal(false)
                  }}
                >
                  {t("button:closeThisWindow")}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}

export default WebinarRegistrationCard
