import React from "react"
import { Col, Row } from "antd"

import PageRoot from "../components/PageRoot"
import { graphql } from "gatsby"
import WebinarRegistrationCard from "../components/WebinarRegistrationCard"

const WebinarPage = ({ data, pageContext }) => {
  const webinar = data.ats.webinars_by_pk
  const authors = webinar.author_rels.map(rel => rel.author).filter(Boolean)

  const webinarRemoteData = pageContext.webinarData

  if (!webinarRemoteData) {
    return (
      <PageRoot
        title={webinar.title}
        description={webinar.short_description}
        image={webinar.landscape?.localeFile?.childImageSharp?.fixed?.src}
        showTitle={false}
      >
        <br />
        <br />
        <br />
        <Row justify="center">
          <Col lg={14} md={18} sm={22}>
            <WebinarRegistrationCard
              data={{
                ...webinar,
                name: webinar.title,
                description: webinar.short_description,
                schedules: [],
                presenters: [],
                landscape: webinar.landscape,
                id: webinar.id,
              }}
            />
          </Col>
        </Row>
      </PageRoot>
    )
  }

  return (
    <PageRoot
      title={webinarRemoteData.name}
      description={webinarRemoteData.description}
      image={webinar.landscape?.localeFile?.childImageSharp?.fixed?.src}
      showTitle={false}
    >
      <br />
      <br />
      <br />
      <Row justify="center">
        <Col lg={14} md={18} sm={22}>
          <WebinarRegistrationCard
            data={{
              ...webinar,
              ...webinarRemoteData,
              presenters: authors,
              landscape: webinar.landscape,
              id: webinar.id,
            }}
          />
        </Col>
      </Row>
    </PageRoot>
  )
}

export const query = graphql`
  query getWebinar($webinarId: ats_uuid!) {
    ats {
      webinars_by_pk(id: $webinarId) {
        id
        title
        subtitle
        premium_only
        short_description
        available_date
        url
        landscape {
          ...LandscapeImage
        }
        author_rels {
          author {
            firstname
            lastname
            title
            picture {
              ...AuthorPicture
            }
          }
        }
      }
    }
  }
`

export default WebinarPage
